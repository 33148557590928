export const isDevelopment = window.location.hostname === "app.dev.waterlyapp.com";
export const isLocal = window.location.hostname === "localhost";

const featureFlags = {
  numberInputForIOS: false,
  showSampleManagement: false,
  enableAuditEventsSubscription: false,
  enableLocalization: false,
  enableScheduleManagement: false,
  enableSampleManagement: false,
  flexDataEntry: true,
};

export type FeatureFlags = typeof featureFlags;
export type FeatureFlagsEnum = keyof FeatureFlags;

export const featureFlagBySystemID: Partial<{ [key in FeatureFlagsEnum]: number[] }> = {
  enableScheduleManagement: [
    10, // Lake Zebra, IL
    26, // West Dundee, IL
    899, // Arlington Heights IL
    1040, //	CRWA - Wild Wings WW
    1041, //	CRWA - Wild Wings
    1109, //	CRWA - NBRID WW
    1108, //	CRWA - LSA South Shore WW
    /** Start - Singh Operations **/
    954, // SOS - SAG
    920, // SOS - Calvert Gateway
    910, // SOS - Calvert Gateway WW
    1021, // SOS - Boones Estates WW
    1020, // SOS - Lyons Creek WW
    1022, // SOS - Maryland Manor WW
    1014, // SOS - SAG WW
    1015, // SOS - Boones Estates
    1018, // SOS - Idlewood
    1013, // SOS - Lyons Creek
    1016, // SOS - Maryland Manor
    1017, // SOS - Patuxent MHP
    1023, // SOS - Patuxent WW
    /** End - Singh Operations **/
    1115, // Golden Hills CSD
    1165, // Golden Hills Lands
    /** Start - Edinburgh, IN **/
    75, // 💧 Edinburgh, IN
    1036, // 💩 Edinburgh, IN
    1161, // 📝Supt Log
    1162, // 🏋️‍♂️ Edinburgh, IN
    /** End - Edinburgh, IN **/
    752, // Ramsey Water Company
    1163, //	EJW - Witt
    1170, //	EJW - Delbert
    915, //	Animas Water
    131, // Mukwonago, WI
    1007, // Mukwonago, WI - Wastewater,
    771, // Gurnee, IL WW
    772, // Gurnee, IL Water,
    48, // Mount Zion, IL
    119, //	Bartlett, IL Water
  ],
};

export default featureFlags;
